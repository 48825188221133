<template>
    <div id="home-mobile"  class="show-tabbar">
        <div class="home-header" v-if="token">
            <div class="virtual-input">
                <div
                    :class="['virtual-scroll', anti_animate ? 'anti-animate' : '']"
                    :style="{ transform: 'translateY(-' + index / placeholders.length * 100 + '%' + ')' }"
                >
                    <div v-for="(item,index) in placeholders" class="virtual-scroll-item" :key="index">
                        <van-icon name="search" />
                        <div class="placeholder">{{ item }}</div>
                    </div>
                </div>
            </div>
            <van-icon class="quick-action" color="rgb(67, 122, 239)" name="add" />
            <mobileEmail class="about-me"></mobileEmail>
        </div>
        <div class="header-txt" v-else>
            <span>Hi,  亲爱的朋友, 您当前正处于游客模式</span>
        </div>
        <div class="home-tab"></div>
        
        <van-pull-refresh ref="refresh_ref" :class="[ pullLoading ? 'loading' : refreshed ? 'refreshed' : '']" v-model="pullLoading" @refresh="onRefresh">
            <van-notify v-model="refreshed" type="success">
                <van-icon name="bell" style="margin-right: 4px;" />
                <span>更新成功</span>
            </van-notify>
            <div class="chart">
                <div @click="toCarbonMarket"
                    :class="[
                        'item', nationalMarket.percent === ''  || /^0(.0+)?%$/.test(nationalMarket.percent)?'loading':nationalMarket.percent.startsWith('-') ? 'down' :'up'
                    ]"
                >
                    <div class="name">碳行情(大全国市场)</div>
                    <div class="value">
                        <span>{{ nationalMarket.start_price || "--" }}</span>
                    </div>
                    <div class="percent">
                        {{ nationalMarket.percent || "--" }}
                        <template v-if="nationalMarket.amplitude">
                            (
                                <span >
                                    {{ nationalMarket.amplitude }}
                                </span>
                            )
                        </template>
                        
                        <template v-else>
                            --
                        </template>
                    </div>
                </div>
                <div @click="toCarbonBazaar"
                    :class="[
                        'item', trade.discount_premium === ''  || /^0(.0+)?%$/.test(trade.discount_premium) ?'loading':(trade.discount_premium.startsWith('-') ? 'down' :'up')
                    ]"
                   
                >
                    <div class="name">全国碳市场（大宗交易）</div>
                    <div class="value">
                        <span>{{ trade.total || "--" }}</span>
                    </div>
                    <div class="percent">
                        {{ trade.average_price || "--" }}
                        <template v-if="trade.discount_premium">
                            (
                                <span>
                                    {{ trade.discount_premium }}
                                </span>
                            )
                        </template>
                        <template v-else>
                            --
                        </template>
                    </div>
                </div>
            </div>
            <!-- 新闻展示 -->
            <div class="news_wrapper">
                <div class="news">
                    <router-view />
                    <div class="item" v-for="item in news" :key="item.id" @click="handleView(item)">
                        <div class="title">
                            {{ item.title }}
                        </div>
                        <a class="content" v-html="item.content">
                            
                        </a>
                        <div class="source">
                            <span class="time">{{ item.send_time }}</span>
                            <span class="source"> 来源：{{ item.source }} </span>
                            <div class="collect" @click.stop="handleCollectChange(item)">
                                <collect v-if="!item.is_collection" />
                                <collectActive v-else/>
                                <span>
                                        <!-- 收藏 -->
                                     {{item.is_collection ?'已收藏':'收藏' }}
                                    </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div :class="['more', { loading: loading }]" v-loading="loading">
                    <span v-show="scrollCount > 2 && more" @click="handleMore">加载更多</span>
                </div>
            </div>
        </van-pull-refresh>
        <div class="login-info" v-if="!token">
          <div class="login-txt">
            登录查看更多独家优质内容
          </div>
            <van-button type="info"  round size="mini" @click="$router.push('/login')">一键登录</van-button>
         
        </div>
    </div>
</template>
<script>
import { Notify } from 'vant';
import { mobileEmail,collect, collectActive} from '../../../core/icon';
import { mapState } from 'vuex';
export default {
    name: "TotalWrapper",
    components: {
        mobileEmail,
        collect,
        collectActive
    },
    data() {
        return {
            pullLoading: false,
            refreshed: false,
            nationalMarket: {
                percent: ''
            },
            trade: {
                discount_premium: ''
            },
            pagination: {
                page: 1,
                limit: 20,
                page_count: 0,
            },
            news: [],
            more: true,
            scrollCount: 0,
            scrollRef: null,
            loading: false,
            placeholders: [
                '赵诣新基金首次开放申购',
                'ChatGPT概念还能炒多久',
                '市场回暖，私募成长VS价值策略怎么',
                '华为超融合战略发布在即，相关概念',
                '赵诣新基金首次开放申购',
            ],
            time: 5000,
            timeout: null,
            anti_animate: false,
            index: 0,
            
        };
    },
    computed:{
        ...mapState(['token','userInfo']),
    },
    activated() {
        this.getNationalMarket()
        this.getTrade()
        this.getNews();
        this.$EventBus.$on("search", (keywords) => {
            //需要执行的代码
            console.log("NewsWrapper, EventBus, search", keywords);
            this.pagination = {
                ...this.$options.data().pagination,
            };
            this.getNews(keywords);
        });
       
        this.handleSetScroll();
        this.loading = false;
        this.more = true;
        this.scrollCount = 0;
        this.refreshed = false;
        this.pullLoading = false;
        // this.getNews(this.$route.query.keyword);
        // window.addEventListener("scroll", this.handleScroll);
        let parent_ref = this.$refs.refresh_ref;
        parent_ref instanceof Array && (parent_ref = parent_ref[0]);
        console.log('parent_ref', parent_ref);
        let ref = parent_ref.$el && parent_ref.$el.children instanceof HTMLCollection ? parent_ref.$el.children[0] : null;
        console.log('ref', ref, );
        this.scrollRef = ref;
        // this.scrollRef && this.scrollRef.addEventListener("scroll", this.handleScroll);
        window.addEventListener("scroll", this.handleScroll);
    },
    deactivated() {
        // this.scrollRef && this.scrollRef.removeEventListener("scroll", this.handleScroll);
        window.removeEventListener("scroll", this.handleScroll);
        window.clearInterval(this.timeout);
    },
    methods: {
        async onRefresh(){
            this.loading = false;
            this.more = true;
            this.scrollCount = 0;
            this.pagination = {
                ...this.$options.data().pagination,
            };
            this.pullLoading = true;
            this.refreshed = false;
            let promise = Promise.all([
                this.getNationalMarket(),
                this.getTrade(),
                this.getNews(),
            ]);
            await promise;
            this.refreshed = true;
            this.pullLoading = false;
            setTimeout(() => {
                this.refreshed = false;
            }, 1000)
        },
        handleSetScroll(){
            this.timeout = window.setInterval(() => {
                window.setTimeout(() => {
                    if (this.anti_animate) this.anti_animate = false;
                    this.index++;
                    if (this.index === this.placeholders.length - 1 ){
                        window.setTimeout(() => {
                            this.anti_animate = true;
                            this.index = 0;
                        }, 1000)
                    }
                }, 2000)
            }, this.time)
        },
        getNationalMarket() {
            return new Promise(resolve => {
                //   http://192.168.23.15:8000/article/carbonpricelist
                this.api.postFormAPISM(
                    {
                        page: 1,
                        limit: 1,
                    },
                    "/article/carbonpricelist"
                ).then((res) => {
                    this.nationalMarket = res.data.data.daily_price_list[0];
                    console.log("getNationalMarket", this.nationalMarket);
                    resolve(true);
                }).catch((error) => {
                    console.log("error", error);
                    resolve(false);
                });
                // console.log(this.nationalMarket,'111111111');
                // console.log(this.trade,'222222');
            })
        },
        getTrade() {
            return new Promise(resolve => {
                // http://192.168.23.15:8000/http://192.168.23.15:8000/article/carbonsalelist
                this.api.postFormAPISM(
                    {
                        page: 1,
                        limit: 1,
                    },
                    "/article/carbonsalelist"
                ).then((res) => {
                    this.trade = res.data.data.daily_sale_list[0];
                    console.log("getTrade", this.trade);
                    resolve(true);
                }).catch((error) => {
                    console.log("error", error);
                    resolve(false);
                });
            })
        },
        handleViewCandlestick(all) {
            // console.log('all', all);
            // this.$router.push({
            // 	path: '/candlestick',
            // 	query: {
            // 		...all ? { all: 1 } : {},
            // 	}
            // });
            let text = this.$router.resolve({
                path: "/candlestick",
                query: {
                    ...(all ? { all: 1 } : {}),
                },
            });
            // console.log("text", text.href);
            // 打开一个新的页面
            window.open(text.href, "_blank");
        },
        getNews(keywords) {
            let tags = this.userInfo.tag_list;
            return new Promise(resolve => {
                // http://192.168.23.15:8000/article/home
                this.api.postFormAPISM(
                    {
                        ...this.pagination,
                        keywords: keywords || "",
                        tags:tags ,
                        tag_scored:0,
                    },
                    "/article/home"
                ).then((res) => {
                    let temp = res.data.data.article_info_list;
                    this.more = temp.length === this.pagination.limit;
                    for (let i = 0; i < temp.length; i++) {
                        temp[i].content = this.replaceImage(temp[i].content, temp[i].id);
                    }
                    this.news = temp;
                    resolve(true);
                    // console.log('getNews', this.news.map(item => item.content.length));
                    //   this.pagination.page_count = res.data.data.pagination.page_count;
                }).catch((error) => {
                    console.log("error", error);
                    resolve(false);
                });
            })
        },
        replaceImage(content, id) {
            let reg =
                /({{[^}}]+.(bmp|jpg|png|tif|gif|pcx|tga|exif|fpx|svg|psd|cdr|pcd|dxf|ufo|eps|ai|raw|WMF|webp|avif|apng)}})/g;
            let reg2 = /^{{([^}}]+)}}$/;
            let length = 300;
            let temp = content.replace(/<br>|\s/g, "");
            // console.log('replaceImage', content.match(/<br>/g));
            let tempImage = temp.match(reg) || [];
            // tempImage.length && console.log("tempImage", id);
            for (let i = 0; i < tempImage.length; i++) {
                let url = reg2.exec(tempImage[i]);
                if (url instanceof Array) {
                    url = url[1];
                    temp = temp.replace(tempImage[i], "");
                    // temp = temp.replace(tempImage[i], imageTemplate(ImageBasicUrl + url));
                }
            }
            // function getSliceStr(str){
            //     if ( str.length < length || (/[\u4e00-\u9fa5]/.test(str[length]) && /[\u4e00-\u9fa5]/.test(str[length-1]))){
            //         return str.slice(0, length) + (!(str.length < length) ? '....' : '');
            //     } else {
            //         length++;
            //         return getSliceStr(str);
            //     }
            // }
            // temp = getSliceStr(temp);
            // console.log('replaceImage, length', length, temp.length);
            return temp;
        },
        handleMore(text) {
            console.log("handleMore", text, this.scrollCount);
            this.pagination.page++;
            // this.pagination.limit += 20;
            let tags = this.userInfo.tag_list;
            this.api
                .postFormAPISM(
                    {
                        ...this.pagination,
                        tag_scored: this.news[this.news.length - 1].tag_scored,
                        tags:tags
                    },
                    "/article/home"
                )
                .then((res) => {
                    this.news = [
                        ...this.news,
                        ...res.data.data.article_info_list.map((item) => {
                            return {
                                ...item,
                                content: this.replaceImage(item.content, item.id),
                            };
                        }),
                    ];
                    console.log('handleMore', this.news);
                    //   this.pagination.page_count = res.data.data.pagination.page_count;
                    this.more =
                        res.data.data.article_info_list.length === this.pagination.limit;
                    if (text) {
                        this.loading = false;
                        this.scrollCount++;
                    }
                })
                .catch((error) => {
                    console.log("error", error);
                });
        },
        handleView({ title, id }) {
            this.$router.push({
                path: "/news",
                query: {
                    id,
                },
            });
            //   let text = this.$router.resolve({
            //     path: "/news",
            //     query: {
            //       id,
            //     },
            //   });
            //   console.log("text", text.href);
            //   // 打开一个新的页面
            //   window.open(text.href, "_blank");
        },
        handleScroll() {
            let ref = this.scrollRef;
            // console.log('handleScroll', this.news.length, this.loading, this.scrollCount);
            if (!this.news.length || this.loading || this.scrollCount > 2) return;
            // 距顶部
            var scrollTop = ref.scrollTop;
                // document.documentElement.scrollTop || document.body.scrollTop;
            // 可视区高度
            var clientHeight = ref.clientHeight;
                // document.documentElement.clientHeight || document.body.clientHeight;
            // 滚动条总高度
            var scrollHeight = ref.scrollHeight;
                // document.documentElement.scrollHeight || document.body.scrollHeight;
            // let targetHeight = scrollHeight - 257 - 140;
            // console.log(scrollTop, clientHeight, scrollHeight, targetHeight,  targetHeight - scrollTop - clientHeight);
            // console.log(clientHeight, scrollHeight - scrollTop ,scrollHeight, scrollTop);
            // return;
            if (clientHeight + 30 > scrollHeight - scrollTop) {
                console.log('handleScroll');
                this.loading = true;
                // return
                setTimeout(() => {
                    this.handleMore("scroll");
                }, 500);
            }
        },
        // 去到碳行情表格
        toCarbonMarket() {
            this.$router.push({ path: "/carbonMarket" });
        },
        // 去碳全国碳市场表格
        toCarbonBazaar() {
            this.$router.push({ path: "/carbonBazaar" });
        },
        //收藏
      async  handleCollectChange(item)
        {
             // 未登录，跳转回登录页面
            if(!this.token)
            {
                this.$router.push('/login');
            }
            else{
                let is_collection = item.is_collection;
                let res =  await this.api.postFormAPISM(
                {
                    article_id: item.id,
                    is_collection: !is_collection,
                },
                '/article/collection'
            )
            // item.is_collection = !item.is_collection
            this.getNews();
            }
        }
    },
};
</script>
<style lang="less">
    @import './index.less';

</style>
